<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="wxmini">
      <p class="tit">小程序定制流程</p>
      <p class="help" @click="goHelp">帮助教程</p>
      <div class="process">
        <div class="item">
          <div class="item-l active">
            <p>1</p>
            <p>{{info.status == 0 ? '授权' : '已授权'}}</p>
          </div>
          <div class="fgx">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="item" >
          <div class="item-l" :class="info.status != 0 ? 'active' : ''">
            <p>2</p>
            <p>{{ verifyMessage }}</p>
          </div>
          <div class="fgx">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="item" >
          <div class="item-l" :class="(info.status == 3 || info.status == 4) ? 'active' : ''">
            <p>3</p>
            <p>{{publishMessage}}</p>
          </div>
        </div>
      </div>
      <div class="verify-reason" v-if="info.verify_reason">
        {{info.verify_reason}}
      </div>
      <!-- <div class="wx-info"  v-if="info.status == 4">
        <img :src="info.app_qr" width="246" height="246" />
        <p>{{info.name}}</p>
      </div> -->
      <div class="wxmini-form">
        <p class="tip">注意：小程序类目需设置为 <span>教育服务 > 在线教育</span>，否则审核不通过。</p>
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="160">
          <FormItem label="小程序名称" prop="name">
            <div class="wx-info-cont" >
              <Input v-model="formValidate.name" placeholder="请输入小程序名称" style="width: 300px" />
            </div>
          </FormItem>
          <FormItem label="AppID(小程序ID)" prop="appID">
            <div class="wxmini-form-item">
              <Input v-model="formValidate.appID" placeholder="请输入AppID(小程序ID)" :disabled="info.status == 3 || info.status == 4"></Input>
              <span @click="showModal">获取方法</span>
            </div>
          </FormItem>
          <FormItem label="AppSecret(小程序秘钥)" prop="appSecret">
            <div class="wxmini-form-item">
              <Input v-model="formValidate.appSecret" placeholder="请输入AppSecret(小程序秘钥)"></Input>
              <span @click="showModal">获取方法</span>
            </div>
          </FormItem>
          <FormItem label="立即授权" prop="appSecret">
            <Button @click="authorization">立即授权</Button>
          </FormItem>
          <FormItem label="上传客服微信二维码" prop="contactWxQrcode">
            <div class="wx-info-cont">
              <Upload :action="constant.URL + '/uploadfile/upload'"
                      :data="{
                      type:3
                    }"
                      accept=".jpg,.png,.jpeg,.gif"
                      :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                      :on-success="fileSuccess"
                      :show-upload-list="false"
              >
                <Button v-if="!formValidate.contactWxQrcode" icon="ios-cloud-upload-outline">上传微信二维码</Button>
                <img v-else :src="formValidate.contactWxQrcode" class="wx-code" />
              </Upload>
              <Tooltip style="margin-left: 20px">
                <Icon type="ios-help-circle-outline" :size="20" />
                <div slot="content" class="wx-info-desc">
                  <p>在小程序-立即激活中显示，方便用户与您取得联系。</p>
                  <img src="../../assets/images/wx/wx_info_show.png" width="150" />
                </div>
              </Tooltip>
            </div>
          </FormItem>
          <FormItem label="客服微信号" prop="contactWx">
            <div class="wx-info-cont">
              <Input v-model="formValidate.contactWx" placeholder="请输入微信号" style="width: 300px" />
              <Tooltip style="margin-left: 20px">
                <Icon type="ios-help-circle-outline" :size="20" />
                <div slot="content" class="wx-info-desc">
                  <p>在小程序-立即激活中显示，方便用户与您取得联系。</p>
                  <img src="../../assets/images/wx/wx_info_show.png" width="150" />
                </div>
              </Tooltip>
            </div>
          </FormItem>
          <FormItem label="客服手机号" prop="contactMobile">
            <div class="wx-info-cont">
              <Input v-model="formValidate.contactMobile" placeholder="请输入手机号" style="width: 300px" />
              <Tooltip style="margin-left: 20px">
                <Icon type="ios-help-circle-outline" :size="20" />
                <div slot="content" class="wx-info-desc">
                  <p>在小程序-立即激活中显示，方便用户与您取得联系。</p>
                  <img src="../../assets/images/wx/wx_info_show.png" width="150" />
                </div>
              </Tooltip>
            </div>
          </FormItem>
          <FormItem label="小程序二维码" prop="app_qr">
            <div class="wx-info-cont">
              <Upload :action="constant.URL + '/uploadfile/upload'"
                      :data="{
                      type:3
                    }"
                      accept=".jpg,.png,.jpeg,.gif"
                      :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                      :on-success="appQRSuccess"
                      :show-upload-list="false"
              >
                <Button v-if="!formValidate.app_qr" icon="ios-cloud-upload-outline">上传小程序二维码</Button>
                <img v-else :src="formValidate.app_qr" class="wx-code" />
              </Upload>
            </div>
          </FormItem>
          <FormItem>
            <div v-if="isSuper">
              <Button v-if="info.status==1" type="primary" style="margin-right: 10px;" @click="reviewSuccessful()">审核成功</Button>
              <Button v-if="info.status==1" type="primary" style="margin-right: 10px;" @click="verifyModal=true">审核失败</Button>
              <Button v-if="info.status==3" type="primary" style="margin-right: 10px;" @click="successfullyPublished()">发布成功</Button>
            </div>
            <Button type="primary" @click="submit">{{info.status == 2 ? '重新提交' : '提交'}}</Button>
          </FormItem>
          <FormItem>
            <p class="register-applet" @click="registerApplet">没有小程序，立即注册</p>
          </FormItem>
        </Form>

      </div>
      <Modal
          v-model="getInfoModal"
          title="AppID、AppSecret获取方法"
          width="1000px"
          footer-hide
          @on-cancel="closeModal"
      >
        <div class="get-wx-info">
          <p>1.登录小程序管理平台，mp.weixin.qq.com</p>
          <p>2.进入“开发 - 开发管理 - 开发设置”</p>
          <img src="../../assets/images/getWxInfo.png" />
        </div>
      </Modal>
      <Modal
          v-model="verifyModal"
          title="审核失败原因"
          @on-ok="reviewFailed"
          @on-cancel="cancelVerifyModal">
        <div class="verify-reason">
          <p>审核失败原因:</p>
          <Input class="verify-reason-input" v-model="verifyReason" type="textarea" placeholder="请输入审核失败原因" />
        </div>
      </Modal>
    </div>
  </div>

</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "wzmini",
  data(){
    return {
      Cookies:Cookies,
      formValidate:{
        name:'',
        appID:'',
        appSecret:'',
        contactWxQrcode:'',
        contactWx:'',
        contactMobile:'',
        app_qr:"",
        id:""
      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入小程序名称', trigger: 'blur' }
        ],
        appID: [
          { required: true, message: '请输入AppID(小程序ID)', trigger: 'blur' }
        ],
        appSecret: [
          { required: true, message: '请输入AppSecret(小程序秘钥)', trigger: 'blur' }
        ],
        contactWxQrcode: [
          { required: true, message: '请上传客服微信二维码', trigger: 'change' }
        ],
        contactWx: [
          { required: true, message: '请输入微信号', trigger: 'blur' }
        ],
        contactMobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        app_qr:[
          { required: false }
        ]
      },
      getInfoModal:false,
      info:{},
      verifyMessage:'审核',
      publishMessage:'发布',
      xcx_id:"",
      verifyReason:"",
      verifyModal:false,
      isSuper:false
    }
  },
  created(){
    this.xcx_id=this.$route.query.xcx_id||"";
    if(this.xcx_id){
      this.isSuper=true;
    }
    this.getInfo();
  },
  methods:{
    fileSuccess(response){  //上传文件成功
      this.formValidate.contactWxQrcode = response.data.info.upload_path;
    },
    appQRSuccess(res){
      this.formValidate.app_qr=res.data.info.upload_path;
    },
    // 审核成功
    reviewSuccessful(){
      const { formValidate }=this;
      let params = {
        id:formValidate.id,
        verify_status: 3,
      };
      this.api.wxmini.thirdpartyVerify(params).then((res)=>{
        this.$Message.success("审核成功");
        this.getInfo();
      });
    },
    // 审核失败
    reviewFailed(){
      const { formValidate }=this;
      let params = {
        id:formValidate.id,
        verify_status: 2,
        verify_reason:this.verifyReason
      };
      this.api.wxmini.thirdpartyVerify(params).then(()=>{
        this.$Message.success("操作成功");
        this.getInfo();
      });
    },
    cancelVerifyModal(){
      this.verifyReason = '';
      this.verifyModal = false;
    },
    // 发布成功
    successfullyPublished(){
      const { formValidate }=this;
      if(!formValidate.name){
        this.$Message.error("请输入小程序名称");
        return;
      }
      if(!formValidate.app_qr){
        this.$Message.error("请上传小程序二维码");
        return;
      }
      let params = {
        id:formValidate.id,
        verify_status: 4,
        name:formValidate.name,
        app_qr:formValidate.app_qr
      };
      this.api.wxmini.thirdpartyVerify(params).then(()=>{
        this.$Message.success("发布成功");
        this.getInfo();
      });
    },
    showModal(){
      this.getInfoModal = true;
    },
    closeModal(){
      this.getInfoModal = false;
    },
    getInfo(){
      const params={
        site_id:"-1"
      }
      if(this.xcx_id){
        params.id=this.xcx_id
      }
      this.api.wxmini.thirdpartyDetail(params).then((res)=>{
        this.info = res.info;
        if(this.info.status == 1){
          this.verifyMessage = '审核中';
        }else if(this.info.status == 2){
          this.verifyMessage = '审核失败';
        }else if(this.info.status == 3){
          this.verifyMessage = '审核通过';
          this.publishMessage = '发布中'
        }else if(this.info.status == 4){
          this.verifyMessage = '审核通过';
          this.publishMessage = '已发布'
        }
        this.formValidate.name = this.info.name;
        this.formValidate.appID = this.info.appid;
        this.formValidate.appSecret = this.info.appsecret;
        this.formValidate.contactWxQrcode = this.info.site.contact_wx_qrcode;
        this.formValidate.contactWx = this.info.site.contact_wx;
        this.formValidate.contactMobile = this.info.site.contact_mobile;
        this.formValidate.app_qr=this.info.app_qr;
        this.formValidate.id=this.info.id;
      })
    },
    submit(){
      this.$refs['formValidate'].validate((valid)=>{
        if(valid){
          let params = {
            name:this.formValidate.name,
            id:this.info.id,
            site_id:this.info.site_id,
            appid:this.formValidate.appID,
            appsecret:this.formValidate.appSecret,
            contact_wx:this.formValidate.contactWx,
            contact_mobile:this.formValidate.contactMobile,
            contact_wx_qrcode:this.formValidate.contactWxQrcode
          };
          this.api.wxmini.thirdpartyUpdate(params).then((res)=>{
            this.$Message.success('提交成功');
            this.getInfo();
          })
        }
      })

    },
    authorization(){
      //window.open('https://wxcomponent-75928-14709-5-1314631399.sh.run.tcloudbase.com/#/authorize','_blank')
      window.open('https://wxopen.edu360.cn/#/authorize','_blank')
    },
    registerApplet(){
      window.open('https://mp.weixin.qq.com','_blank')
    },
    goHelp(){
      this.$router.push({
        path:'/wx-help'
      });
    }
  }
}
</script>

<style scoped lang="scss">
.wxmini{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .tit{
    text-align: center;
    font-size: 18px;
  }
  .help{
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color:#2d8cf0;
    cursor: pointer;
  }
  .process{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .item{
      display: flex;
      justify-content: flex-start;

      .item-l{

        text-align: center;
        >p:nth-child(1){
          width: 80px;
          height: 80px;
          border:1px solid #dcdee2;
          border-radius: 80px;
          background-color: #FFFFFF;
          line-height: 80px;
          font-size:20px ;
        }
        >p:nth-child(2){
          margin-top: 10px;
          font-size: 14px;
        }
      }
      .item-l.active{
        >p:nth-child(1){
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
        >p:nth-child(2){
          color: #2d8cf0;
        }
      }
      .fgx{
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        >p:nth-child(1){
          margin: 0 0 0 10px;
          height: 2px;
          width: 100px;
          color: #808695;
          border-bottom: 2px solid #808695;
        }
        >p:nth-child(2){
          margin: 0 auto;
          width: 0;
          height: 0;
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent transparent #808695;
        }
      }
    }
  }
  .verify-reason{
    margin: 20px auto;
    color:#ed4014;
    width: 800px;
    border:1px solid #dcdee2;
    border-radius: 4px;
    padding: 20px;
    font-size: 14px;
    line-height: 26px;
  }
  .wxmini-form{
    margin: 30px auto;
    width: 800px;

    .tip{
      margin-bottom: 20px;
      >span{
        color:#2d8cf0;
        font-weight: bold;
      }
    }
    .wx-code{
      width: 123px;
      height: 123px;
    }
    .wxmini-form-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        margin-left: 10px;
        width: 100px;
        color:#2d8cf0;
        cursor: pointer;
      }
    }
    .register-applet{
      color:#2d8cf0;
      cursor: pointer;
    }
  }
}
.get-wx-info{
  >p{
    line-height: 30px;
    font-size: 14px;
  }
  >img{
    width: 900px;
  }
}
.wx-info{
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  >p{
    margin-top: 20px;
  }
}
.wx-info-cont{
  display: flex;
  justify-content: flex-start;

  .wx-info-desc{
    text-align: center;
    >p{
      margin-bottom: 20px;
      white-space:pre-wrap;
      text-align: left;
    }
  }
}

</style>
